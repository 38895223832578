<template>
    <BaseLayout>
      <div class="ol-wrapper ol-wrapper--large" >
        <img :src="require('@/assets/images/about/About-us_banner.jpg')"  width="100%" class="banner-top-about" />
        <div class="break-16"></div>    
        <div class="row">
          <div class="col-md-12 col-lg-6">
              <h1 class="h1-about">Kami siap menjadi <span style="color:#CA352B">#Sahabat</span><span style="color:#364B7B">RamahRumahmu</span> untuk wujudkan hunian impian</h1>
          </div>
          <div class="col-md-12 col-lg-6">
            
          </div>
          <div class="col-md-12 col-lg-6">
              
          </div>
          <div class="col-md-12 col-lg-6 about">
            <p>Sejak tahun 2021, Mitra Olympic Retail Experience (MORE) Furniture, di bawah Olympic Group, hadir sebagai platform belanja furnitur resmi, baik online maupun offline. Kami menawarkan pengalaman belanja modern dan inspiratif, dengan layanan custom interior dan koleksi produk yang fungsional. </p>
            <br>
            <p>Didukung oleh pengalaman lebih dari 40 tahun, kami menyediakan berbagai produk berkualitas seperti furnitur panel knock down, plastik, sofa bed, dan springbed. Berfokus pada kualitas, inovasi, dan kepuasan pelanggan, MORE Furniture berkomitmen memenuhi kebutuhan furnitur masyarakat Indonesia, membawa kenyamanan dan gaya ke setiap hunian.</p>
          </div>
        </div>  
        <div class="break-16"></div>    
        <div class="break-16"></div>    
        <div class="row">
          <div class="col-12">
            <h2 class="title-about" >Komitmen Kami</h2>
            <div class="break-16"></div> 
            <div class="wrapper-5">
              <div>
                  <img :src="require('@/assets/images/benefit/about-terpercaya.png')"  width="30%"  />
                  <h3 class="h3-komitmen">Produk Terpercaya lebih dari 40 Tahun</h3>
                  <p class="p-komitmen">MORE Furniture di bawah naungan Olympic Group yang terpercaya lebih dari 40 tahun</p>
              </div>
              <div>
                  <img :src="require('@/assets/images/benefit/about-ternyaman.png')"  width="30%"  />
                  <h3 class="h3-komitmen">Pengalaman Belanja Ternyaman</h3>
                  <p class="p-komitmen">Berbagai plaform berbelanja paling nyaman tersedia baik offline maupun online</p>
              </div>
              <div>
                  <img :src="require('@/assets/images/benefit/about-pelayanan.png')"  width="30%"  />
                  <h3 class="h3-komitmen">Pelayanan Pelanggan Unggul</h3>
                  <p class="p-komitmen">Memastikan Kepuasan pelanggan dengan pelayanan terbaik adalah prioritas utama kami</p>
              </div>
              <div>
                  <img :src="require('@/assets/images/benefit/about-variasi.png')"  width="30%"  />
                  <h3 class="h3-komitmen">Pilihan Produk Furniture Bervariasi</h3>
                  <p class="p-komitmen">Kami menawarkan berbagai pilihan produk untuk memenuhi berbagai preferensi gaya Anda</p>
              </div>
              <div>
                  <img :src="require('@/assets/images/benefit/about-terjangkau.png')"  width="30%"  />
                  <h3 class="h3-komitmen">Jaminan Harga yang Terjangkau</h3>
                  <p class="p-komitmen">MORE Furniture menawarkan produk berkualitas dengan harga yang terjangkau.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="break-16"></div>    
        <div class="break-16"></div>    
        <div class="row">
          <div class="col-md-12 col-lg-4">
            <div class="visi">
              <h2 style="color:white;font-weight: bold">Visi</h2>
              <br>
              <p class="p-visi">Menjadi toko furniture kebanggaan keluarga Indonesia dengan menghadirkan kenyamanan dan keharmonisan dalam setiap rumah keluarga Indonesia melalui furniture yang diproduksi, didistribusi dan dijual oleh Indonesia dari Indonesia untuk keluarga Indonesia.</p>
            </div>
          </div>
          <div class="col-md-12 col-lg-8">
            <div class="misi">
              <h2 style="font-weight: bold">Misi</h2>
              <br>
              <div class="wrapper-2 p-misi">
                <div>
                  Memberikan pengalaman belanja furniture kekinian dan nyaman<br>
                  Mewujudkan rumah idaman keluarga Indonesia<br>
                  Memberikan inspirasi tatanan interior rumah keluarga Indonesia<br>
                  Memberikan harga furniture yang terjangkau  
                </div>
                <div>
                  Memberikan free konsultasi untuk mewujudkan rumah idaman <br>
                  Memberikan pelayanan terbaik untuk keluarga Indonesia  <br>
                  Memberikan kualitas furniture terbaik di kelasnya untuk keluarga Indonesia <br>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="break-16"></div>    
        <div class="break-16"></div>     
        <div class="row">
          <div class="col-12">
            <h2 class="title-about" >Lini Bisnis MORE</h2>
            <div class="break-16"></div> 
            <div class="wrapper-3">
              <div class="card-lini">
                <img :src="require('@/assets/images/about/About-us_retail.jpg')"  width="100%"  />
                <div class="content-lini">
                  <h3>MORE Retail</h3>
                  <p>Destinasi utama Anda dalam berbelanja furnitur favorit. Temukan koleksi furnitur eksklusif dan terbaru dari Olympic Furniture. </p>      
                  <a class="ol-btn" href="https://maps.app.goo.gl/y8WwqUPFLWdjVzTg6" target="_blank">Menuju Lokasi</a>
                </div>
              </div>
              <div class="card-lini">
                <img :src="require('@/assets/images/about/About-us_website-putih.jpg')"  width="100%"  />
                <div class="content-lini">
                  <h3>MORE Website</h3>
                  <p>Pengalaman berbelanja furnitur impian lebih cepat dan mudah dimanapun kapanpun lewat website MORE. </p>      
                  <a class="ol-btn" href="/promotion">Belanja Sekarang</a>
                </div>
              </div>
              <div class="card-lini">
                <img :src="require('@/assets/images/about/About-us_custon-interior.jpg')"  width="100%"  />
                <div class="content-lini">
                  <h3>MORE Custom</h3>
                  <p>Realisasikan interior impian Anda bersama MORE Custom Interior dan dapatkan banyak penawaran menarik lainnya. </p>      
                  <a class="ol-btn" href="/desain-interior">Gratis Konsultasi</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="break-16"></div>    
        <div class="break-16"></div>   
        <!-- <iframe width="100%" class="video-testimoni" src="https://www.youtube.com/embed/lpS6XbMJTu8?si=O1-CACJiXs0WwWRg" title="More Furniture" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <div class="break-16"></div>    
        <div class="break-16"></div>     -->
      </div>
    </BaseLayout>
</template>
<script>
import BaseLayout from '@/components/landing/BaseLayout.vue';
export default {
    name: "About",
    metaInfo() {
        return{
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components:{
        BaseLayout
    },
    methods:{
      jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
    },
    async mounted(){
        this.jqScrolling2top();
    }
}
</script>
<style scoped lang="css">

.visi{
  background: #364B7B;
  border-radius: 16px;
  color: white;
  padding: 24px;
}

.misi{
  background: #F3F4F7;
  border-radius: 16px;
  color: #364B7B;
  padding: 24px;
}

.banner-top-about{
  margin-top:32px;
}

.h1-about{
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  /* or 150% */

  color: #141414;
}
.about{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;

  color: #6C7C93;
}
.wrapper-5 {
  display: grid;
  grid-template-columns: repeat(5, 1.2fr);
  /* grid-auto-rows: 45%; */
  column-gap: 20px;
  row-gap: 20px;
}

.wrapper-3 {
  display: grid;
  grid-template-columns: repeat(3, 1.2fr);
  /* grid-auto-rows: 45%; */
  column-gap: 20px;
  row-gap: 20px;
}

.wrapper-2 {
  display: grid;
  grid-template-columns: repeat(2, 1.2fr);
  /* grid-auto-rows: 45%; */
  column-gap: 20px;
  row-gap: 20px;
}

.title-about{
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #141414;

}

.h3-komitmen{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  margin-top:8px;
}

.p-komitmen{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top:8px;
  color: #141414;
}

.p-visi{
  font-size: 16px;
  line-height: 22px;

  color: #FFFFFF;
}

.p-misi{
  font-size: 16px;
  line-height: 22px;
  color: #364B7B;
}
@media (max-width: 768px) {
  .banner-top-about{
    margin-top:0px;
  }

  .h1-about{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #141414;
    margin-bottom: 16px;
  }

  .about{
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: justify;

    color: #6C7C93;
  }

  .wrapper-5 {
    display: grid;
    grid-template-columns: repeat(2, 1.2fr);
    /* grid-auto-rows: 45%; */
    column-gap: 8px;
    row-gap: 8px;
  }

  .wrapper-3 {
    display: grid;
    grid-template-columns: repeat(1, 1.2fr);
    /* grid-auto-rows: 45%; */
    column-gap: 20px;
    row-gap: 20px;
  }

  .wrapper-2 {
    display: grid;
    grid-template-columns: repeat(1, 1.2fr);
    /* grid-auto-rows: 45%; */
    column-gap: 20px;
    row-gap: 20px;
  }
}

.video-testimoni{
    height: 650px;
}

.card-lini{
  background-color: #F3F4F7;
  border-radius: 8px;
}
.content-lini{
  padding:16px;
}
.content-lini h3{
  color:#364B7B;
  font-size: 18px;
  font-weight: bold;
}

.content-lini p{
  color:#6C7C93;
  font-size: 14px;
  margin-top:8px;
}


.content-lini a{
  margin-top:8px;
  color:white;
}
@media (max-width: 768px) {
    .video-testimoni{
        height: 300px;
    }
  }

</style>